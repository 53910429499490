@import 'src/Styles/mixins/mixins.scss';
@import 'src/Styles/settings/variables';

.bank-account {
  background-color: transparent !important;
  width: 100%;
  box-shadow: none;

  .account-status {
    &.active {
      background: $success-color;

      &::after {
        border-bottom: 8px solid $success-color;
      }
    }

    &.disabled {
      background: $dark-color;

      &::after {
        border-bottom: 8px solid $darker-color;
      }
    }

    position: absolute;
    right: -$smallMargin;
    top: $smallMargin;
    padding: 0 $smallPadding;
    color: $lighter-color;
    font-size: $small;
    font-weight: bold;
    box-shadow: $shadow;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      position: absolute;
      bottom: -8px;
      right: 0;
      transform: rotate(180deg);
    }

    svg {
      margin-right: $spaceMargin;
    }
  }

  .icon-container {
    margin: 0 $margin;
    padding: 0 !important;
  }

  &.active {
    background-image: linear-gradient(to left, #003c83, #4a90e2);
  }

  .bank-account-body {
    &.active {
      cursor: initial;

      .form-control-plaintext, .input-container {
        color: $lighter-color !important;
      }
    }

    .icon {
      width: $bigIconSize;
      height: $bigIconSize;

      path {
        fill: $lighter-color;
      }

      &:not(.active) path {
        fill: $dark-color;
      }
    }

    .form {
      height: 100%;

      .account-info{
        flex-wrap: wrap;
      }

      .bank-account-data {
        @include flexBox(center, normal);
        flex-direction: column;
        height: 100%;
        flex-wrap: wrap;
        overflow: hidden;

        .routing-wrapper {
          width: 100%;
          display: flex;
        }

        @include mq(phone-wide) {
          .routing-wrapper {
            width: 50%;
          }
        }

        .form-group {
          margin-bottom: 0;
          width: 100%;

          &:not(:last-child) {
            margin-bottom: $smallMargin;
          }

          &.routing {
            &-number {
              width: 50%;
              margin-right: $smallMargin;
            }
            &-check-digit {
              width: calc(50% - #{$smallMargin});
            }
          }

          @include mq(phone-wide) {
            width: 50%;

            &.routing {
              &-number {
                width: 40%
              }
              &-check-digit {
                width: calc(20% - #{$smallMargin});
              }
            }

            &:not(.routing-check-digit):nth-child(even) {
              margin-left: $margin;
              width: calc(50% - #{$margin})
            }
          }
        }

        &.active {
          border-left-color: $lighter-color;
        }

        .account-info {
          @include flexBox(space-between, center);
          flex-wrap: wrap;
        }

        .account-info-modal{
          @include flexBox(space-between, center);
          flex-direction: row;
          flex-wrap: wrap;
        }
      }



      .input-container {
        color: $dark-color;

        &.active {
          color: $lighter-color;
        }

        label {
          margin-bottom: 0;
          margin-right: 20px;
        }

        .bold {
          font-weight: bold;
        }

        .form-control {
          &-plaintext {
            color: $dark-color;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            &.active {
              color: $lighter-color;
            }
          }
          width: auto;
          margin-left: 10px;
        }
      }
    }

    .button-group {
      @include flexBox(flex-end, center);

      .active-label {
        color: #4e9501;
        margin-left: 10px;
      }
    }
  }
}
