@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.auth {
  height: 100vh;
  flex-direction: column;
  flex: 0 0 auto;
  display: flex;

  .content {
    min-height: auto;
  }

  .title-wrapper {
    &.invisible {
      visibility: hidden;
    }
    &.visible {
      visibility: visible;
    }
    h3 {
      font-weight: 600;
      color: #6610f2;
    }
    p {
      margin-bottom: 20px;
      @include mq('tablet') {
        margin-bottom: 40px;
      }
    }
  }

  .image-wrapper {
    img {
      display: none;
      @include mq('tablet') {
        width: 100%;
        max-width: 500px;
        display: inline;
      }
    }
  }
}

.auth-card-form {
  @include flexBox(flex-start, stretch);
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  border-radius: 5px;
  -webkit-box-shadow: $shadow;
  box-shadow: $shadow;
  padding: $largePadding;

  .auth-card-form-header {
    @include flexBox(center, center);
    flex-direction: column;
    min-height: 60px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .auth-card-form-logo {
      max-height: 58px;
      max-width: 154px;
    }

    .title {
      margin-top: $margin;
    }

    &-label {
      margin: 0;
      padding: 0;
      font-size: 1.3rem;
      font-weight: 500;
      color: #3d4465;
    }
  }

  .auth-card-form-body {
    .password-checker {
      margin-bottom: $margin;
    }

    .alert-danger {
      text-align: center;
    }

    text-align: left;
    fieldset {
      margin: $margin 0;
      :last-child {
        margin-bottom: 0;
      }
    }

    .form-group {
      .label {
        font-weight: bold;
        margin-bottom: .5rem;
      }
      .form-control {
        display: block;
        width: 100%;
        height: calc(2.8rem + 2px);
        padding: 0.65rem 1rem;
        font-size: 1rem;
        line-height: 1.5;
        color: #495057;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ebedf2;
        border-radius: 0.25rem;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        margin-bottom: .5rem;
        &:focus {
          color: $dark-color;
        }
        &::placeholder {
          color: #a1a8c3;
          opacity: 1;
        }
        &:disabled, .form-control[readonly] {
          border-color: transparent;
          background-color: rgba($dark-color, 0.1);
        }
        &:active, &:hover, &:focus, &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px white inset !important;
          -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
          -webkit-transition-delay: 9999s;
        }
      }
    }
  }

  .auth-card-form-actions {
    min-height: 85px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .create-account-link-wrapper {
      @include flexBox(center, center);
      margin-top: $bigMargin;

      .link {
        font-weight: normal;
        font-size: $regular;
      }
    }
    .buttons-wrapper {
      @include flexBox(space-between, center);
      .btn {
        padding: $smallPadding $padding;

        &.sign-in {
          font-size: $big;
        }

        &-brand {
          color: #fff;
          background-color: #5d78ff;
          border-color: #5d78ff;
          &:focus {
            box-shadow: none;
          }
          &:hover:not([disabled]) {
            background-color: #294dff;
          }
          &:disabled {
            background-color: #89bbff;
            border-color: #89bbff;
          }
        }
      }
      .separator {
        border-top: 1px solid #ebedf2;
        width: 100%;
        margin: 10px 0;
      }
      .subtitle {
        color: #5d78ff;
        font-size: 1.3rem;
      }
    }

    .link {
      text-decoration: none;
      position: relative;
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      color: $dark-color;

      &:hover {
        cursor: pointer;
        text-decoration: none !important;
      }
    }
  }
}

.change-registeraccount-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.change-registeraccount-validation {
  display: flex;
  flex: 0 1 auto;
  align-self: auto;
  order: 0;
  width: 50%;
}

.change-registeraccount-form {
  align-self: auto;
  order: 0;
  width: 45%;
  margin-left: 5px;
  padding-left: 10px;
  border-left: 1px solid #cecece;
}

@media (max-width: 576px) {
  .change-registeraccount-style {
    flex-direction: column-reverse !important;
    gap: 2rem;
  }

  .change-registeraccount-validation,
  .change-registeraccount-form {
    width: 100% !important;
    border-left: 0;
    padding-left: 0;
  }
}

.change-updatepassword-style {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: normal;
  align-items: normal;
  align-content: normal;
}

.change-updatepassword-validation {
  display: flex;
  flex: 0 1 auto;
  align-self: auto;
  order: 0;
  width: 50%;
}

.change-updatepassword-form {
  align-self: auto;
  order: 0;
  width: 45%;
  margin-left: 5px;
  padding-left: 10px;
  border-left: 1px solid #cecece;
}

@media (max-width: 576px) {
  .change-updatepassword-style {
    flex-direction: column-reverse !important;
    gap: 2rem;
  }

  .change-updatepassword-validation,
  .change-updatepassword-form {
    width: 100% !important;
    border-left: 0;
    padding-left: 0;
  }
}