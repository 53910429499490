@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.billing-payment-container {
  .header {
    margin: $smallPadding 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
      margin-bottom: unset;
    }
  }
}

.billing-receipt-modal {
  .header {
    float: right;
    margin-bottom: unset;
  }
}

.billing-payment-details {
  .info {
    justify-content: flex-start;
  }

  .sub-info {
    .resume-field {

      &-title {
        font-size: $small;
      }

      &-value {
        font-size: $small;
        font-weight: normal;

        img {
          margin-right: $spaceMargin;
        }
      }
    }
  }

  hr {
    margin: $margin 0;
  }
}
