@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.card-form {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  border-radius: $box-radius;
  .card-form-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 $padding;
    border-bottom: 1px solid $light-color;
    min-height: 60px;
    border-top-left-radius: $tinyBorderWidth;
    border-top-right-radius: $tinyBorderWidth;
    &-label {
      margin: 0;
      padding: 0;
      font-size: $small;
      font-weight: 500;
      color: $darker-color;
    }
  }

  .card-form-body {
    display: flex;
    flex-direction: column;
    border-bottom-left-radius: $tinyBorderWidth;
    border-bottom-right-radius: $tinyBorderWidth;
    .form-group {

      label {
        color: $dark-color;
        opacity: 0.7;
        font-size: $regular;
        margin-bottom: $spaceMargin;
      }

      &.switch {
        @include flexBox(flex-start, center);

        label {
          margin-right: $spaceMargin;
        }

        .react-switch {
          margin-left: $smallMargin;
        }
      }

      .form-control {
        padding: $smallerPadding $small;
        font-size: $regular;
        color: $dark-color;
        background-color: $lighter-color;
        background-clip: padding-box;
        border: solid 1px rgba($dark-color, 0.3);
        border-radius: $box-radius;
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        height: 45px;

        &-static {
          display: block;
          width: 100%;
        }

        @include mq('tablet') {
          height: 35px;
        }

        &::placeholder {
          color: rgba($dark-color, 0.5);
        }

        &:disabled, .form-control[readonly] {
          color: rgba($dark-color, 0.8);
          border-color: transparent;
          cursor: not-allowed;
          background-color: rgba($dark-color, 0.1);
        }

        &:disabled::placeholder {
          color: $dark-color;
        }

        &:active, &:hover, &:focus, &:-webkit-autofill {
          -webkit-box-shadow: 0 0 0 1000px transparent inset !important;
          -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
          -webkit-transition-delay: 9999s;
        }
      }
    }
  }

  .card-form-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    border-top: 1px solid #ebedf2;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .buttons-wrapper {
      display: flex;
      justify-content: center;

      .btn {
        margin: 0 5px;
      }
    }
  }
}

.card-form-body {
  .form-group {
    .form-control {
      &-static {
        display: block;
      }
    }
  }
}
