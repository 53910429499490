// Alinha conteúdo com flexbox
@mixin flexBox($hAlign, $vAlign) {
  -webkit-align-items: $vAlign;
  -ms-flex-align: $vAlign;
  -moz-align-items: $vAlign;
  align-items: $vAlign;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: $hAlign;
  -ms-justify-content: $hAlign;
  -moz-justify-content: $hAlign;
  justify-content: $hAlign;
}

@mixin animation-keyframes {
  $animation-number: 0 !default !global;
  $animation-number: $animation-number + 1 !global;
  $name: unquote("การเคลื่อนไหวที่-#{$animation-number}");
  animation-name: $name;
  @at-root {
    @keyframes #{$name} {
      @content;
    }
  }
}
// Cria um sombra em um box
@mixin boxShadow($color, $opacity) {
  -webkit-box-shadow: 0 1px 3px 0 rgba($color, $opacity);
  -moz-box-shadow: 0 1px 3px 0 rgba($color, $opacity);
  box-shadow: 0 1px 3px 0 rgba($color, $opacity);
}

// Adiciona o conteudo obrigatorio para o before e after
@mixin pseudo($content: "", $display: block, $pos: absolute) {
  content: $content;
  display: $display;
  position: $pos;
}

@mixin border-bottom-shadow() {
  -webkit-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
}

//media queries
$breakpoints: (
        "phone-small":  375px,
        "phone":        400px,
        "phone-wide":   480px,
        "phablet":      560px,
        "tablet-small": 640px,
        "tablet":       768px,
        "tablet-wide":  1024px,
        "desktop":      1248px,
        "desktop-wide": 1440px
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @-ms-keyframes #{$animation-name} {
    @content;
  }
  @-o-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($str) {
  -webkit-animation: #{$str};
  -moz-animation: #{$str};
  -ms-animation: #{$str};
  -o-animation: #{$str};
  animation: #{$str};
}
