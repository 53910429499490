@import 'src/Styles/settings/variables';
@import 'src/Styles/mixins/mixins';

.bank-accounts {
  &-select {
    .dropdown {
      width: 100%;
      margin-bottom: $spaceMargin;

      .dropdown-item {
        max-height: 400px;
        overflow: hidden;
        padding: 10px;
      }

      .btn-toggle {
        padding: $smallPadding;
        border-color: $light-color3;
        text-align: start;
        background-color: transparent !important;
        color: $dark-color !important;
        width: 100%;

        &:hover {
          color: $dark-color;
          background-color: transparent !important;
        }

        &:focus {
          border: none;
        }

        &:after {
          position: absolute;
          right: 3%;
          top: 50%;
        }
      }
    }

    .menu {
      width: 100%;
      max-height: 250px;
      overflow-y: scroll;
      transform: none !important;
      top: $largePadding !important;
      .icons {
        margin: $smallMargin 0;

        ul {
          @include flexBox(space-between, center);
          list-style: none;
          padding: 0;
          margin-bottom: 0;

          li {
            &:first-child {
              padding-left: $smallPadding;
            }
            &:last-child {
              padding-right: $smallPadding;
            }
            width: 100%;
            padding: 0 $smallerPadding;
            cursor: pointer;
          }
        }
      }
    }
  }
}
