@import "src/Styles/settings/variables";
@import "src/Styles/mixins/mixins";

.card {
  border: none !important;
  border-radius: $card-border-radius;
  box-shadow: $card-box-shadow;

  &.transparent {
    background-color: transparent !important;
  }

  .card-header{
    background-color: #fff !important;
    margin-bottom: $middleMargin;
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-bottom: none;

    h2 {
      @include mq(phone-small) {
        margin: 0;
      }
    }
  }

  .card-body {
    padding: 0;
  }

}
