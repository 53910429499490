@import 'src/Styles/mixins/mixins';
@import 'src/Styles/settings/variables';

.create-bank-account {
  fieldset {
    .form-group {
      &:first-child {
        display: flex;
        flex-wrap: wrap;

        .control-label {
          flex: 1 0 100%;
          margin-bottom: $smallMargin;
        }

        .radio {
          flex: 1 0 50%
        }
      }
    }
  }

  .form-group {
    .control-label {
      margin-bottom: $spaceMargin;
    }

    .document-label-wrapper {
      display: flex;
      align-items: center;

      label {
        margin-right: $spaceMargin;
        margin-bottom: 0;
      }
    }
  }

  .form-control {
    &:disabled {
      background-color: transparent;
      border: none;
      padding: 0;
      font-size: $regular;
    }
  }

  &.footer {
    margin-top: $margin;
    padding-top: 20px;
  }
}

.document-tooltip {
  box-shadow: $card-box-shadow;

  &:after {
    box-sizing: border-box;
    border: 8px solid transparent;
    border-color: transparent transparent $lighter-color $lighter-color !important;
    box-shadow: -3px 3px 3px -1px rgba(0, 0, 0, 0.2);
    transform: rotate(45deg);
  }
}