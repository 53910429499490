@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.big-button {
  @include flexBox(center, center);
  padding: $middlePadding;
  text-align: center;
  border-radius: $box-radius;
  cursor: pointer;
  border: 1px solid;
  background-color: $lighter-color;
  width: 100%;
  min-height: $bigButtonHeight;
  min-width: $bigButtonWidth;
  font-size: $regular;

  &:not(last-child) {
    margin-bottom: $margin;
  }

  &.active {
    color: $lighter-color !important;

    path {
      fill: $lighter-color !important;
    }

    p {
      font-weight: bold;
    }
  }

  svg {
    width: $iconSize;
    height: $iconSize;
  }

  p {
    margin: 0 0 0 $smallPadding;
    font-size: $regular;
  }

  @include mq('tablet') {
    padding: $smallPadding;

    svg {
      width: $iconSize;
      height: $iconSize;
    }

    p {
      margin: 0 0 0 $spaceMargin;
      font-size: $small;
    }
  }

  @include mq('tablet-wide') {
    padding: $smallPadding;

    svg {
      width: $iconSize;
      height: $iconSize;
    }

    p {
      margin: 0 0 0 $smallPadding;
      font-size: $regular;
    }
  }
}
