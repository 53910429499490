@import "src/Styles/settings/variables";

.camera-container {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  #display-error {
    z-index: 110000;
  }

  .react-html5-camera-photo {
    position: absolute;
    z-index: 100000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $darker-color;

    video, img {
      object-fit: cover;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .display-error {
      width: 100vw;
      height:100vh;
    }
  }

  .loading, .error {
    z-index: 100000;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: rgba($darker-color, 0.2);
    color: $lighter-color;
    font-size: $bigger;
    font-weight: bold;

    .error-message {
      padding: $padding;
      text-align: center;
    }
  }

  .top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba($darker-color, 0.2);
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $lighter-color;
    padding: $padding;

    .title {
      text-align: center;
      font-weight: bold;
      font-size: $bigger;
    }

    .close-button {
      position: absolute;
      right: 0;
      color: $lighter-color;
      margin-right: $margin;
    }
  }

  .facing-mode {
    position: absolute;
    bottom: 30px;
    right: 40px;
    color: $lighter-color;
    z-index: 100000;
  }

  &.preview {
    img {
      display: inline-block !important;
    }
    video {
      display: none !important;
    }
    #container-circles {
      display: none;
    }

    .action-buttons {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: $bigMargin $largePadding;
      color: $lighter-color;
      z-index: 100000;
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: rgba($darker-color, 0.2);

      .send {
        font-size: $bigger;
        font-weight: bold;
        display: flex;
        align-items: center;

        span {
          margin-left: $spaceMargin;
        }
      }
    }
  }
}