// Font
$font-family: 'Roboto', sans-serif;

//Base color
$primary-color: #f19215;
$secondary-color: #4a90e2;
$auxiliar-color: #F50057;

// Darks
$darker-color: #000;
$darker-color--alpha-01: rgba(0, 0, 0, 0.1);
$darker-color--alpha-02: rgba(0, 0, 0, 0.2);
$darker-color--alpha-03: rgba(0, 0, 0, 0.3);
$darker-color--alpha-04: rgba(0, 0, 0, 0.4);
$darker-color--alpha-05: rgba(0, 0, 0, 0.5);
$darker-color--alpha-06: rgba(0, 0, 0, 0.6);
$darker-color--alpha-07: rgba(0, 0, 0, 0.7);
$darker-color--alpha-08: rgba(0, 0, 0, 0.8);
$darker-color--alpha-09: rgba(0, 0, 0, 0.9);
$dark-color: #555;

$gray-color: #bbb;
$light-gray-color:rgba(85, 85, 85, 0.05);

// Lighters
$light-color: #efefef;
$light-color2: #aaa;
$light-color3: #ddd;
$lighter-color: #fff;
$light-warning-color: #ffeaa4;

// Menu
$menu-background-color: #fafafa;

// Status
$success-color: #62bb00;
$error-color: #f82222;
$warning-color: #ffca1b;

// Actions
$action-color: #4a90e2;
$darker-action-color: #294dff;
$btn-secondary-color: #f3f3f3;

// Font color
$light-font-color: #b5b5b5;
$dark-gray-font-color: #5E5E5E;

// Card
$card-background-color: #ddd;

//Font Size
$largest: 30px;
$large: 26px;
$biggest: 20px;
$bigger: 18px;
$big: 16px;
$regular: 14px;
$small: 12px;
$smaller: 10px;

//Metrics

//Button
$bigButtonHeight : 56px;
$bigButtonWidth : 128px;

// Padding
$padding: 20px;
$middlePadding: 15px;
$smallPadding: 10px;
$smallerPadding: 5px;
$bigPadding: 30px;
$largePadding: 40px;

// Marging
$margin: 20px;
$middleMargin: 15px;
$smallMargin: 10px;
$bigMargin: 30px;
$spaceMargin: 5px;
$largeMargin: 35px;

// Icons
$iconWidth: 40px;
$iconHeight: 30px;

// Border Width
$borderWidth: 20px;
$smallBorderWidth: 10px;
$tinyBorderWidth: 10px;
$bigBorderWidth: 30px;
$largeBorderWidth: 38px;

// Border
$lightBorder: solid 1px $light-color;
$darkBorder: solid 1px $light-color3;

// Icon
$xlargeIconSize: 128px;
$largeIconSize: 64px;
$biggerIconSize: 48px;
$bigIconSize: 32px;
$iconSize: 16px;
$mediumIconSize: 20px;
$smallIconSize: 8px;


// Card
$cardWidth: 320px;
$cardHeight: 160px;

// Others
$menuWidth: 210px;
$box-radius: 4px;
$big-box-radius: 8px;
$card-border-radius: 4px;
$card-box-shadow: 0 1px 2px 0 rgba(0,0,0,.3);
$shadow: 0 1px 2px 0 rgba(0,0,0,.3);
$btn-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);

$menu-horizontal-height: 60px;
$menu-submenu-horizontal-height: 40px;

$clickable-line-height: 2.5em;
$card-filter-border-color: #e0dede;
$no-data-background-color: #f6f6f6;
