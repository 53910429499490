@import "src/Styles/settings/variables";
@import "src/Styles/mixins/mixins";

.calendar {
  display: block;
  position: relative;
  width: 100%;
  background: $lighter-color;
  border-radius: $box-radius;
  box-shadow: $shadow;

  .header {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 115%;
    padding: 1.5em 0;
    border-bottom: 1px solid;

    .icon {
      cursor: pointer;
      transition: .15s ease-out;

      &:hover {
        transform: scale(1.75);
        transition: .25s ease-out;
      }

      &:first-of-type {
        margin-left: 1em;
      }

      &:last-of-type {
        margin-right: 1em;
      }
    }
  }

  .days {
    text-transform: capitalize;
    font-weight: bold;
    color: $dark-color;
    font-size: $regular;
    border-bottom: 1px solid $light-color;

    div {
      padding: $smallPadding 0;

      &:not(:last-child) {
        border-right: 1px solid $light-color;
      }
    }
  }

  .body {
    .cell {
      position: relative;
      overflow: hidden;
      height: 3em;
      cursor: pointer;
      transition: 0.25s ease-out;

      @include mq(phone-wide) {
        height: 5em;
      }

      .number {
        position: absolute;
        font-size: 70%;
        line-height: 1;
        top: .75em;
        right: 45%;
        font-weight: 700;
      }

      .bg {
        font-weight: 700;
        line-height: 1;
        opacity: 0;
        font-size: 8em;
        position: absolute;
        top: -.2em;
        right: -.05em;
        transition: .25s ease-out;
        letter-spacing: -.07em;
      }

      .content {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        font-weight: bold;

        .statusIcon {
          width: 100%;
          margin: 0 $smallMargin;

          @include mq(tablet, max) {
            justify-content: center;
            margin-bottom: $spaceMargin;
          }

          :first-child {
            margin-right: 2px;
          }
        }

        .symbol {
          font-size: $smaller;
          margin-right: 2px;
          margin-top: 2px;
        }

        .amount {
          font-size: $small;
        }
      }

      &:hover {
        background: $light-color;
        transition: 0.5s ease-out;
      }

      &:hover .bg, .calendar .body .selected .bg  {
        opacity: 0.05;
        transition: .5s ease-in;
      }

      &:last-child {
        border-right: none;
      }
    }

    .selected {
      background-color: $light-color;
    }

    .row {
      .cell {
        &:not(:last-child) {
          border-right: 1px solid $light-color;
          //border: 1px solid $light-color;
        }
      }
      &:last-child {
        border-bottom: none;
      }
    }

    .col {
      border-bottom: 1px solid $light-color;
      flex-grow: 0;
      flex-basis: calc(100%/7);
      width: calc(100%/7);
    }

    .disabled {
      color: $light-color;
      pointer-events: none;

      .amount {
        color: $light-color;
      }
    }
  }
}
