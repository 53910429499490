@import '../../../Styles/settings/variables';
@import '../../../Styles/mixins/mixins';

.auth-card-form-body{
  &.forgot-pass {
    margin: 10px;

    .buttons-wrapper {
      flex-direction: column;

      .link {
        margin-top: $margin;
      }
    }

    &.success {
      @include flexBox(center, center);
      flex-direction: column;
      text-align: center;

      .auth-card-form-actions {
        min-height: unset;
      }
    }
  }

  &.recover-access {
    margin-top: $margin;

    .subtitle {
      text-align: center;
      margin-bottom: $margin;
    }

    .buttons-wrapper {
      justify-content: center;
    }

    .auth-card-form-actions {
      min-height: unset;
    }
  }
}

.forgot-pass-form {
  .title{
    margin-top: 15px;
  }

  .subtitle {
    margin: $margin 0;
  }

}
