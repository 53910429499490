@import '../../../Styles/mixins/mixins';
@import '../../../Styles/settings/variables';

.card-info {
  &-row {
    @include flexBox(space-between, normal);
    flex-direction: column;
    overflow: hidden;
    label {
      color: $dark-color;
      font-size: $regular;
      line-height: $regular;
    }
    span {
      color: $darker-color;
      font-size: $regular;
      line-height: $bigger;
      margin: 0;
    }
    padding: $smallPadding 0;
  }
  &-row-block {
    display: block;
  }
  .label-block, .value-block {
    display: block;
    width: 100%;
  }
}
